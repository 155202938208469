<template>
  <div>
    <div class="flex justify-between items-center mt-8 mb-5">
      <h2 class="text-lg">Preference</h2>
      <button
        class="btn btn-blue btn-sm"
        :disabled="configuration.loading"
        @click.prevent="saveConfigurationChanges"
      >
        Save changes
      </button>
    </div>

    <template v-if="hasCaasAccess">
      <div class="mb-5 flex flex-row">
        <label class="custom-switch">
          <input
            type="checkbox"
            class="custom-switch-input"
            v-model="configuration.data.caas_secured.value"
          />
          <span class="custom-switch-label"></span>
        </label>
        <span class="text-13 font-normal mt-1 ml-5">
          CAAS Secured
        </span>
      </div>

      <div class="mb-5 flex flex-row">
        <label class="custom-switch">
          <input
            type="checkbox"
            class="custom-switch-input"
            v-model="configuration.data.caas_partially_secured.value"
          />
          <span class="custom-switch-label"></span>
        </label>
        <span class="text-13 font-normal mt-1 ml-5">
          CAAS Partially Secured
        </span>
      </div>

      <div class="mb-5 flex flex-row">
        <label class="custom-switch">
          <input
            type="checkbox"
            class="custom-switch-input"
            v-model="configuration.data.caas_unsecured.value"
          />
          <span class="custom-switch-label"></span>
        </label>
        <span class="text-13 font-normal mt-1 ml-5">
          CAAS Unsecured
        </span>
      </div>

      <div class="mb-5 flex flex-row">
        <label class="custom-switch">
          <input
            type="checkbox"
            class="custom-switch-input"
            v-model="configuration.data.caas_send_funds_to_merchant.value"
          />
          <span class="custom-switch-label"></span>
        </label>
        <span class="text-13 font-normal mt-1 ml-5">
          CAAS Send Funds To Merchant
        </span>
      </div>
    </template>

    <template v-if="hasPayAccess">
      <div class="mt-5">
        <form-group
          name="max_order_amount"
          type="money"
          :form="configuration"
          v-model="configuration.data.max_order_amount.value"
        >
          Max Order Amount
        </form-group>
      </div>

      <div class="mb-5 flex flex-row">
        <label class="custom-switch">
          <input
            type="checkbox"
            class="custom-switch-input"
            v-model="configuration.data.spread_payment_enabled.value"
          />
          <span class="custom-switch-label"></span>
        </label>
        <span class="text-13 font-normal mt-1 ml-5">
          Enabled Spread Payment
        </span>
      </div>
    </template>
  </div>
</template>
<script>
export default {
  data() {
    return {
      configuration: this.$options.basicForm([
        { name: 'caas_partially_secured', rules: 'required' },
        { name: 'caas_secured', rules: 'required' },
        { name: 'caas_unsecured', rules: 'required' },
        { name: 'caas_send_funds_to_merchant', rules: 'required' },
        { name: 'max_order_amount', rules: 'required|money' },
        { name: 'enable_two_factor', rules: 'required' },
        { name: 'spread_payment_enabled', rules: 'required' }
      ])
    };
  },
  computed: {
    merchant() {
      return this.$parent?.profile;
    },
    accesses() {
      return this.merchant?.vendorAccess;
    },
    hasCaasAccess() {
      return !!this.accesses?.find(access => access.slug === 'caas');
    },
    hasPayAccess() {
      return !!this.accesses?.find(access => access.slug === 'pay');
    }
  },
  watch: {
    merchantConfig() {
      this.mapMerchantConfig();
    }
  },
  mounted() {
    this.mapMerchantConfig();
  },
  methods: {
    mapMerchantConfig() {
      for (let key in this.configuration.data) {
        this.configuration.data[key].value =
          this.$parent.profile?.configuration[key] || false;
      }
    },
    async saveConfigurationChanges() {
      this.configuration.loading = true;
      await this.sendRequest(
        'admin.merchants.saveConfigurationChanges',
        this.merchant?.id,
        {
          data: this.getFormData(this.configuration),
          success: () => this.$parent.$emit('success'),
          error: error => console.log(error)
        }
      ).finally(() => (this.configuration.loading = false));
    }
  }
};
</script>
